const sendForm = (formObj) => {
  return new Promise((resolve, reject) => {
    const form = new FormData();
    for (let key in formObj) {
      form.append(key, formObj[key]);
    }

    const request = new XMLHttpRequest();
    const url = window.location.href;
    request.open("POST", url, true);
    request.onloadend = () => {
      const res = JSON.parse(request.response);

      if (!res.success) {
        reject(res);
      } else if (res.success) {
        resolve(true);
      }
    };
    request.send(form);
  });
};

export { sendForm };
