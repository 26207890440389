<template>
  <form v-if="!state.sended" @submit.prevent="submit" class="rent-form">
    <span class="rent-form__heading">Заявка на аренду</span>
    <span class="rent-form__car_name">{{ carName }}</span>
    <SimpleInput
      :error="state.error.name"
      @input="state.error.name = ''"
      v-model="state.form.name"
      id="username"
      placeholder="Имя"
    />
    <SimpleInput
      :error="state.error.phone"
      @input="state.error.phone = ''"
      v-model="state.form.phone"
      id="phone"
      placeholder="Телефон"
      type="tel"
      :mask="['+7 ### ###-##-##']"
    />
    <div class="simple_input">
      <div
        @click.stop="state.showDatePicker = !state.showDatePicker"
        class="simple_input__input simple_input__input--date"
      >
        <input
          :value="dateValue"
          class="input"
          :readonly="true"
          placeholder="Даты"
          id="date"
        />
        <svg
          :class="state.showDatePicker && 'rotate'"
          width="17"
          height="9"
          viewBox="0 0 17 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 1L8.5 8L1 0.999999"
            stroke="#1F0052"
            stroke-linecap="round"
          />
        </svg>
      </div>
      <div class="date_picker" v-show="state.showDatePicker">
        <VDatePicker v-model.range="state.form.range" mode="date" />
      </div>
    </div>
    <div class="filters_form__discount">
      <input v-model="state.form.withDriver" id="driver" type="checkbox" />
      <label for="driver">
        <svg
          width="24"
          height="23"
          viewBox="0 0 24 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="23"
            height="22"
            rx="1.5"
            stroke="#1F0052"
          />
          <path
            v-show="state.form.withDriver"
            d="M7.5 11L11.25 16L17 7"
            stroke="#1F0052"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span> С водителем </span>
      </label>
    </div>
    <div class="rent-form__price_container">
      <div v-show="state.dateDiff" class="rent-form__price">
        <span>Аренда автомобиля на {{ state.dateDiff }}</span>
        <span
          ><b>{{ state.price }} ₽</b></span
        >
      </div>
    </div>
    <div class="rent-form__footer">
      <button
        class="btn_with_corner"
        :disabled="!state.form.range.end && !state.form.range.start"
      >
        Отправить
      </button>
      <p>
        Нажимая кнопку “Отправить” вы соглашаетесь с политикой
        конфиденциальности
      </p>
    </div>
  </form>
  <div class="rent-form--sended" v-else>
    <span class="rent-form__heading">Заявка на аренду</span>
    <p class="rent-form__txt">
      Ваша заявка отправлена. В ближайшее время мы вам перезвоним
    </p>
    <button @click="$emit('close')" class="btn_with_corner">ок</button>
  </div>
</template>

<script setup>
import { computed, reactive, watch } from "vue";
import plural from "plural-ru";
import spaceInNumbers from "@/utils/spaceInNumbers.js";
import { sendForm } from "@/service/form.js";
import SimpleInput from "./SimpleInput.vue";

const props = defineProps({
  carName: {
    type: String,
  },
  carPrice: {
    type: String,
  },
});

const emits = defineEmits(["close"]);

const state = reactive({
  form: {
    name: "",
    phone: "",
    withDriver: false,
    range: {
      start: "",
      end: "",
    },
  },
  error: {
    name: "",
    phone: "",
  },
  sended: false,
  showDatePicker: false,
  dateDiff: "",
  price: "",
});

const dateValue = computed(() => {
  const { start, end } = state.form.range;
  if (!start && !end) {
    return "";
  }
  return (
    `${start.getDate()}.${start.getMonth() + 1}.${start.getFullYear()}-` +
    `${end.getDate()}.${end.getMonth() + 1}.${end.getFullYear()}`
  );
});

const submit = async () => {
  try {
    const sendObj = { ...state.form };
    sendObj.range = dateValue.value;
    sendObj.type = "аренда";
    sendObj.car = props.carName;
    await sendForm(sendObj);
    state.sended = true;
  } catch (error) {
    state.error.name = error.name;
    state.error.phone = error.phone;
  }
};

watch(
  () => state.form.range,
  () => {
    const diffTime = state.form.range.end - state.form.range.start;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    state.dateDiff = plural(diffDays, "%d день", "%d дня", "%d дней");
    state.price = spaceInNumbers(diffDays * +props.carPrice.replace(" ", ""));
    state.showDatePicker = false
  }
);
</script>

<style lang="scss">
.rent-form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &--sended {
    align-items: center !important;
    gap: 40px !important;
  }

  &__txt {
    width: 440px;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
  }

  &__heading {
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
  }

  &__car_name {
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
  }

  &__price {
    display: flex;
    justify-content: space-between;
    align-items: end;
    color: #000000;
    font-weight: 500;

    b {
      font-size: 20px;
      line-height: 24px;
    }
  }
  .date_picker {
    z-index: 1;
    position: absolute;
    top: 100%;
    right: 0;
  }
  .simple_input {
    border: none;
    background: transparent;
    padding: 0;

    &__input {
      width: 370px;
      &,
      &::before,
      &::after {
        border-color: #1f0052;
      }

      input {
        color: #2b0600;

        &::placeholder {
          color: #9384ab;
        }
      }

      &--date {
        svg {
          transition: 0.5s;
          position: absolute;
          right: 20px;
          top: 20px;

          &.rotate {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    text-align: center;
  }
}

.filters_form {
  &__discount {
    height: 34.5px;
    display: flex;
    align-items: center;

    input {
      display: none;
    }

    label {
      display: flex;
      gap: 10px;
    }
  }
}

@media screen and (max-width: 768px) {
  .rent-form {
    &__price {
      > span:first-of-type {
        max-width: 166px;
      }
    }
  }
}
</style>
