<template>
  <div class="simple_input">
    <div class="simple_input__input">
      <input
        v-if="props.mask"
        class="input"
        :id="props.id"
        :placeholder="props.placeholder"
        type="tel"
        v-mask="props.mask"
        v-model="modelValue"
        @input="$emit('input')"
      />
      <input
        v-else
        class="input"
        :id="props.id"
        :placeholder="props.placeholder"
        type="tel"
        v-model="modelValue"
        @input="$emit('input')"
      />
    </div>
    <span v-if="props.error" class="error-message">{{ props.error }}</span>
  </div>
</template>

<script setup>
const emits = defineEmits(["input"]);

const props = defineProps({
  error: {
    type: String,
  },
  placeholder: {
    type: String,
  },
  id: {
    type: String,
  },
  mask: {
    type: Array,
  },
  type: {
    type: String,
  },
});

const modelValue = defineModel({ default: "" });
</script>

<style lang="scss">
.simple_input {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;

  .error-message {
    position: absolute;
    top: 100%;

    color: #cb1c1c;

    font-family: "Exo 2";
    font-size: 12px;
    line-height: 15px;
    text-align: left;
  }

  &:has(.error-message) {
    .feedback-form__input {
      &,
      &::before,
      &::after {
        border-color: #cb1c1c;
      }
    }
  }

  &__input {
    position: relative;
    overflow: hidden;

    border-width: 0 0 0 2px;
    border-left: 2px solid #d8d8d8;

    &::after,
    &::before {
      box-sizing: border-box;
      position: absolute;
      content: "";
      width: 100%;
      background-color: #f9fdff;
      border: 2px solid #d8d8d8;
      left: 0px;
      z-index: -1;
    }

    &::before {
      height: 20px;
      left: -19px;
      bottom: 0px;
      border-width: 0 3px 2px 0px;
      transform: skew(-45deg);
      transform-origin: right bottom;
    }

    &::after {
      height: calc(100% - 20px);
      top: 0px;
      border-width: 2px 2px 0 0px;
    }

    .input {
      width: 90%;
      box-sizing: border-box;
      padding-left: 20px;
      font-family: "Exo 2";
      font-size: 20px;

      border: none;

      box-sizing: border-box;
      margin: 2px 20px 2px 2px;
      min-height: 46px;

      background: transparent;

      &:focus {
        outline: none;
      }

      &:-webkit-autofill {
        &,
        &:hover,
        &:focus,
        &:active {
          -webkit-background-clip: text;
          -webkit-text-fill-color: #000000;
          transition: background-color 5000s ease-in-out 0s;
          box-shadow: inset 0 0 20px 20px #f9fdff;
        }
      }

      &::placeholder {
        color: #b9b9b9;
      }
    }
  }
}
</style>
