<template>
  <form v-if="!state.sended" @submit.prevent="submit" class="rent-form">
    <span class="rent-form__heading">Заявка на выкуп</span>
    <span class="rent-form__car_name">{{ carName }}</span>
    <SimpleInput
      :error="state.error.name"
      @input="state.error.name = ''"
      v-model="state.form.name"
      id="username"
      placeholder="Имя"
    />
    <SimpleInput
      :error="state.error.phone"
      @input="state.error.phone = ''"
      v-model="state.form.phone"
      id="phone"
      placeholder="Телефон"
      type="tel"
      :mask="['+7 ### ###-##-##']"
    />
    <div class="simple_input">
      <div class="simple_input__input">
        <textarea
          class="input"
          id="comment"
          placeholder="Ваш комментарий"
          v-model="state.form.comment"
          @input="state.error.comment = undefined"
        />
      </div>
      <span v-if="state.error.comment" class="error-message">{{
        state.error.comment
      }}</span>
    </div>
    <div class="rent-form__footer">
      <button class="btn_with_corner">Отправить</button>
      <p>
        Нажимая кнопку “Отправить” вы соглашаетесь с политикой
        конфиденциальности
      </p>
    </div>
  </form>
  <div class="rent-form--sended" v-else>
    <span class="rent-form__heading">Заявка на выкуп</span>
    <p class="rent-form__txt">
      Ваша заявка отправлена. В ближайшее время мы вам перезвоним
    </p>
    <button @click="$emit('close')" class="btn_with_corner">ок</button>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import SimpleInput from "./SimpleInput.vue";
import { sendForm } from "@/service/form.js";

const emits = defineEmits(["close"]);

const props = defineProps({
  carName: {
    type: String,
  },
});

const state = reactive({
  form: {
    name: "",
    phone: "",
    comment: "",
  },
  error: {
    name: undefined,
    phone: undefined,
    comment: undefined,
  },
  sended: false,
});

const submit = async () => {
  try {
    const sendObj = { ...state.form };
    sendObj.car = props.carName;
    sendObj.type = "выкуп";
    await sendForm(sendObj);
    state.sended = true;
  } catch (error) {
    state.error.name = error.name;
    state.error.phone = error.phone;
    state.error.comment = error.comment;
  }
};
</script>

<style lang="scss">
.rent-form {
  textarea {
    color: #2b0600;
    resize: none;
    padding-top: 12px;
    height: 123px;

    &::placeholder {
      color: #9384ab;
    }
  }
}
</style>
