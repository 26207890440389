<template>
  <Teleport to="#car_search">
    <div class="car_search" @scroll.prevent>
      <button @click="showSearchInput">
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.98034 2.64519C9.46568 4.15551 9.46568 6.60421 7.98034 8.11453C6.495 9.62485 4.08679 9.62485 2.60145 8.11453C1.11611 6.60421 1.11611 4.15551 2.60145 2.64519C4.08679 1.13488 6.495 1.13488 7.98034 2.64519ZM9.5943 8.51107C11.0773 6.40913 10.8899 3.46477 9.03212 1.57572C6.96589 -0.525242 3.61589 -0.525242 1.54967 1.57572C-0.516555 3.67669 -0.516555 7.08303 1.54967 9.184C3.46715 11.1337 6.49023 11.2741 8.56689 9.60506L10.737 11.7853C11.0292 12.0789 11.5001 12.076 11.7888 11.7789C12.0775 11.4818 12.0747 11.003 11.7825 10.7094L9.5943 8.51107Z"
            fill="#F9FDFF"
          />
        </svg>
      </button>
      <div class="car_search__search" v-show="showSearch">
        <div class="car_search__form">
          <input ref="searchInput" v-model.trim="searchTxt" type="text" placeholder="Поиск" />
          <div>
            <button @click="searchTxt = ''">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="9"
                  cy="9"
                  r="8.25"
                  stroke="black"
                  stroke-width="1.5"
                />
                <path
                  d="M12 6.00012L6.00006 12.0001"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M12 12L6.00001 6.00001"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </button>
            <button @click="closeSearch">Закрыть</button>
          </div>
        </div>
        <hr class="main_header__divider" />
        <template v-if="searchTxt">
          <div class="car_search__list_container">
            <span class="car_search__not_found" v-if="!searchedCars.length"
              >По запросу “{{ searchTxt }}” ничего не найдено
            </span>
            <span class="car_search__found_count"
              >{{
                searchedCars.length
                  ? `Результаты поиска по запросу “${searchTxt}” (${searchedCars.length})`
                  : "Посмотрите другие наши предложения"
              }}
            </span>
            <div class="car_search__list">
              <template
                v-for="car of searchedCars.length ? searchedCars : carsArr"
                :key="car.title + car.mark"
              >
                <CarCard
                  @openRent="$emit('openRent', $event)"
                  @openBuy="$emit('openBuy', $event)"
                  v-bind="car"
                />
              </template>
            </div>
          </div>
        </template>
        <template v-else-if="popular">
          <div class="car_search__popular">
            <p>Популярные запросы</p>
            <div>
              <template v-for="req of popularArr" :key="req">
                <a @click.prevent="searchTxt = req">{{ req }}</a>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { computed, nextTick, ref } from "vue";
import CarCard from "../components/carslist/CarCard.vue";

const props = defineProps({
  cars: {
    type: String,
  },
  popular: {
    type: String,
  },
});
const emits = defineEmits(["openRent", "openBuy"]);

const searchInput = ref(null)

const showSearch = ref(false);
const searchTxt = ref("");

const carsArr = props.cars && JSON.parse(props.cars);
const popularArr = props.popular && JSON.parse(props.popular);

const searchedCars = computed(() =>
  carsArr.filter((car) => {
    const txt = searchTxt.value.toLowerCase().replace(" ", "");
    const title = car.title.trim().toLowerCase().replace(" ", "");
    const mark = car.mark.trim().toLowerCase().replace(" ", "");
    return (mark + title).includes(txt) || (title + mark).includes(txt);
  })
);

const closeSearch = () => {
  showSearch.value = false;
  searchTxt.value = "";
};

const showSearchInput = async () => {
  showSearch.value = true
  await nextTick()
  searchInput.value.focus()
}
</script>

<style lang="scss">
.car_search {
  > button {
    background: transparent;
    border: none;
    cursor: pointer;
  }

  .main_header__divider {
    width: 100%;
  }

  &__search {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    z-index: 2;

    top: 50px;
    left: 0;
    width: 100%;

    background-color: #f9fdff;

    & > div {
      width: 1170px;
      padding: 25px 0 30px;
    }
  }

  &__form {
    width: 1170px;
    display: flex;
    justify-content: space-between;
    padding: 15px 0 10px;

    input {
      &,
      &:focus {
        outline: none;
        border: none;
      }

      background: transparent;
      &::placeholder {
        font-family: "Exo 2";
        font-size: 16px;
        font-weight: 400;
        line-height: 19.2px;
      }

      font-family: "Exo 2";
      font-size: 16px;
      font-weight: 400;
      line-height: 19.2px;
    }

    button {
      cursor: pointer;

      background: transparent;
      border: none;

      font-family: "Exo 2";
      font-size: 16px;
      font-weight: 400;
      line-height: 19.2px;

      &:first-child {
        padding: 0;
        padding-top: 6px;
      }
    }

    > div {
      display: flex;
      gap: 20px;
    }
  }

  &__list {
    display: flex;
    gap: 30px;

    overflow-y: scroll;
    width: 1195px;
    max-height: 700px;

    flex-wrap: wrap;
    &_container {
      font-size: 12px;
      line-height: 14.4px;
      color: #000000;
    }
  }

  &__found_count {
    display: block;
    margin-bottom: 40px;
  }

  &__not_found {
    font-size: 24px;
    line-height: 28.8px;
    color: #000000;

    display: block;
    margin-bottom: 15px;
  }

  &__popular {
    color: #2b0600;

    padding: 30px 0;

    > p {
      margin-bottom: 20px;

      font-size: 14px;
      font-weight: 600;
      line-height: 16.8px;
    }

    > div {
      max-width: 240px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 10px;

      a {
        font-size: 14px;
      }
    }
  }
}
</style>
