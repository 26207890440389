<template>
  <a :href="link">
    <div class="car_card__wrapper">
      <div v-if="special" class="car_card__special">
        <div class="red_arrow"></div>
        {{ special }}
      </div>
      <div class="car_card">
        <svg
          class="car_card__head"
          width="150"
          height="10"
          viewBox="0 0 150 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 0H150L140 10H10L0 0Z" fill="currentColor" />
        </svg>
        <div class="bamboo">
          <svg
            width="120"
            height="139"
            viewBox="0 0 120 139"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1353_9347)">
              <path
                d="M35.405 120.34C35.0083 115.95 35.0083 111.559 35.3058 107.169L36.9918 86.8126C36.9918 83.8191 35.0083 84.0186 35.0083 84.0186H28.7604H22.5124C22.5124 84.0186 20.5289 83.9188 20.5289 86.8126L22.2149 107.169C22.6116 111.559 22.5124 115.95 22.1157 120.34L20.5289 137.204C20.5289 137.204 20.3306 139 22.3141 139C24.2975 139 28.7604 139 28.7604 139C28.7604 139 33.2232 139 35.2066 139C37.1901 139 36.9918 137.204 36.9918 137.204L35.405 120.34Z"
                fill="#DBDEE3"
              />
              <path
                d="M35.405 63.8622C35.0083 59.4717 35.0083 55.0812 35.3058 50.6906L36.9918 30.3346C36.9918 27.341 35.0083 27.5406 35.0083 27.5406H28.7604H22.5124C22.5124 27.5406 20.5289 27.4408 20.5289 30.3346L22.2149 50.6906C22.6116 55.0812 22.5124 59.4717 22.1157 63.8622L20.5289 80.7258C20.5289 80.7258 20.3306 82.5219 22.3141 82.5219C24.2975 82.5219 28.7604 82.5219 28.7604 82.5219C28.7604 82.5219 33.2232 82.5219 35.2066 82.5219C37.1901 82.5219 36.9918 80.7258 36.9918 80.7258L35.405 63.8622Z"
                fill="#DBDEE3"
              />
              <path
                d="M77.4545 120.34C77.0578 115.95 76.9587 111.559 77.3554 107.169L79.0413 86.8126C79.0413 83.8191 77.0578 84.0186 77.0578 84.0186H70.8099H64.562C64.562 84.0186 62.5785 83.9188 62.5785 86.8126L64.2645 107.169C64.6612 111.559 64.562 115.95 64.1653 120.34L62.5785 137.204C62.5785 137.204 62.3802 139 64.3636 139C66.3471 139 70.8099 139 70.8099 139C70.8099 139 75.2727 139 77.2562 139C79.2397 139 79.0413 137.204 79.0413 137.204L77.4545 120.34Z"
                fill="#DBDEE3"
              />
              <path
                d="M55.7355 130.019C55.3389 127.924 55.3389 125.828 55.6364 123.733L57.3223 113.954C57.3223 112.557 55.3389 112.557 55.3389 112.557H49.0909H42.843C42.843 112.557 40.8595 112.457 40.8595 113.954L42.5455 123.733C42.9422 125.828 42.843 127.924 42.4463 130.019L40.8595 138.102C40.8595 138.102 40.6612 139 42.6446 139C44.6281 139 49.0909 139 49.0909 139C49.0909 139 53.5537 139 55.5372 139C57.5207 139 57.3223 138.102 57.3223 138.102L55.7355 130.019Z"
                fill="#DBDEE3"
              />
              <path
                d="M55.7355 45.0029C55.3389 42.9074 55.3389 40.8119 55.6364 38.7164L57.3223 28.9376C57.3223 27.5406 55.3389 27.5406 55.3389 27.5406H49.0909H42.843C42.843 27.5406 40.8595 27.4408 40.8595 28.9376L42.5455 38.7164C42.9422 40.8119 42.843 42.9074 42.4463 45.0029L40.8595 53.0854C40.8595 53.0854 40.6612 53.9835 42.6446 53.9835C44.6281 53.9835 49.0909 53.9835 49.0909 53.9835C49.0909 53.9835 53.5537 53.9835 55.5372 53.9835C57.5207 53.9835 57.3223 53.0854 57.3223 53.0854L55.7355 45.0029Z"
                fill="#DBDEE3"
              />
              <path
                d="M55.7355 92.4006C55.3389 88.0101 55.3389 83.6196 55.6364 79.229L57.3223 58.7732C57.3223 55.7796 55.3389 55.9792 55.3389 55.9792H49.0909H42.843C42.843 55.9792 40.8595 55.8794 40.8595 58.7732L42.5455 79.229C42.9422 83.6196 42.843 88.0101 42.4463 92.4006L40.8595 109.364C40.8595 109.364 40.6612 111.16 42.6446 111.16C44.6281 111.16 49.0909 111.16 49.0909 111.16C49.0909 111.16 53.5537 111.16 55.5372 111.16C57.5207 111.16 57.3223 109.364 57.3223 109.364L55.7355 92.4006Z"
                fill="#DBDEE3"
              />
              <path
                d="M14.6777 130.019C14.281 127.924 14.281 125.828 14.5785 123.733L16.2645 113.954C16.2645 112.557 14.281 112.557 14.281 112.557H8.13225H1.98349C1.98349 112.557 1.64816e-05 112.457 1.64816e-05 113.954L1.68597 123.733C2.08266 125.828 1.98349 127.924 1.58679 130.019L1.64816e-05 138.202C1.64816e-05 138.202 -0.198331 139.1 1.78514 139.1C3.76861 139.1 8.23142 139.1 8.23142 139.1C8.23142 139.1 12.6942 139.1 14.6777 139.1C16.6612 139.1 16.4628 138.202 16.4628 138.202L14.6777 130.019Z"
                fill="#DBDEE3"
              />
              <path
                d="M14.6777 45.0029C14.281 42.9074 14.281 40.8119 14.5785 38.7164L16.2645 28.9376C16.2645 27.5406 14.281 27.5406 14.281 27.5406H8.13225H1.98349C1.98349 27.5406 1.64816e-05 27.4408 1.64816e-05 28.9376L1.68597 38.7164C2.08266 40.7121 1.98349 42.9074 1.58679 45.0029L1.64816e-05 53.0854C1.64816e-05 53.0854 -0.198331 53.9835 1.78514 53.9835C3.76861 53.9835 8.23142 53.9835 8.23142 53.9835C8.23142 53.9835 12.6942 53.9835 14.6777 53.9835C16.6612 53.9835 16.4628 53.0854 16.4628 53.0854L14.6777 45.0029Z"
                fill="#DBDEE3"
              />
              <path
                d="M14.6777 92.4006C14.281 88.0101 14.281 83.6196 14.5785 79.229L16.2645 58.7732C16.2645 55.7796 14.281 55.9792 14.281 55.9792H8.13225H1.98349C1.98349 55.9792 1.64816e-05 55.8794 1.64816e-05 58.7732L1.68597 79.229C2.08266 83.6196 1.98349 88.0101 1.58679 92.4006L1.64816e-05 109.364C1.64816e-05 109.364 -0.198331 111.16 1.78514 111.16C3.76861 111.16 8.23142 111.16 8.23142 111.16C8.23142 111.16 12.6942 111.16 14.6777 111.16C16.6612 111.16 16.4628 109.364 16.4628 109.364L14.6777 92.4006Z"
                fill="#DBDEE3"
              />
              <path
                d="M93.6198 32.5298C93.8182 32.2304 93.9174 31.8313 94.1157 31.5319C94.4132 30.7337 94.7107 29.9354 95.0083 29.1371C99.1735 29.0373 98.0826 0 98.0826 0C98.0826 0 91.0413 26.3431 94.314 28.9375C94.0165 29.636 93.6198 30.2347 93.3223 31.033C92.0331 25.3453 76.0661 8.78105 76.0661 8.78105C76.0661 8.78105 87.9669 32.2304 92.3306 32.8291C92.1322 33.1285 91.9339 33.5276 91.7355 33.827C91.1405 34.7251 90.4463 35.6231 89.7521 36.5212C89.0578 37.4192 88.3636 38.3173 87.5702 39.1156C86.7769 39.9139 85.9835 40.8119 85.1901 41.6102C84.3967 42.4085 83.6033 43.2067 82.8099 43.9052C81.2231 45.3022 79.6364 46.5994 78.2479 47.5973C78.0496 47.7968 77.7521 47.8966 77.5537 48.0962L79.0413 30.3345C79.0413 27.341 77.0578 27.5406 77.0578 27.5406H70.8099H64.562C64.562 27.5406 62.5785 27.4408 62.5785 30.3345L64.2645 50.6906C64.6612 55.0811 64.562 59.4716 64.1653 63.8622L62.5785 80.7258C62.5785 80.7258 62.3802 82.5219 64.3636 82.5219C66.3471 82.5219 70.8099 82.5219 70.8099 82.5219C70.8099 82.5219 75.2727 82.5219 77.2562 82.5219C79.2397 82.5219 79.0413 80.7258 79.0413 80.7258L77.4545 63.8622C77.0578 60.1701 77.0578 56.4781 77.2562 52.7861C78.0496 52.0876 79.0413 51.1895 80.3306 50.0919C81.6198 48.8945 83.1074 47.3977 84.595 45.8011C85.3884 45.0029 86.0826 44.1048 86.876 43.2067C87.6694 42.3087 88.3636 41.3108 89.0578 40.4128C89.7521 39.4149 90.4463 38.4171 91.0413 37.519C91.5372 36.621 92.1322 35.7229 92.6281 34.8248C95.1074 38.1177 120 24.8464 120 24.8464C120 24.8464 98.9752 28.8378 93.6198 32.5298Z"
                fill="#DBDEE3"
              />
            </g>
            <defs>
              <clipPath id="clip0_1353_9347">
                <rect width="120" height="139" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="car_card__img">
          <div class="car_card__shadow">
            <svg
              viewBox="0 0 643 121"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M287.162 119.041L7.89336 94.5877C-1.23856 93.7881 -2.06879 80.7535 6.88787 78.8017L367.732 0.167269C368.242 0.0562302 368.764 0.0120966 369.285 0.0359832L596.002 10.4313C596.986 10.4764 597.943 10.7628 598.789 11.2651L630.569 30.1232C650.507 41.9545 644.997 72.1996 622.168 76.2402L411.119 113.594C370.217 120.833 328.541 122.665 287.162 119.041Z"
                fill="black"
              />
            </svg>
          </div>
          <img :src="img" alt="car" />
        </div>
        <span class="car_card__title">{{ mark + " " + title }}</span>
        <div class="car_card__info">
          <div>
            <span>Год выпуска</span>
            <p>{{ year }}</p>
          </div>
          <div>
            <span>Комплектация</span>
            <p>{{ equipment }}</p>
          </div>
          <div>
            <span>КПП</span>
            <p>{{ kpp }}</p>
          </div>
          <div>
            <span>Объём двигателя</span>
            <p>{{ volume }}</p>
          </div>
          <div>
            <span>Мощность</span>
            <p>{{ power }} л.с.</p>
          </div>
          <div>
            <span>Топливо</span>
            <p>{{ fuel }}</p>
          </div>
          <div>
            <span>Привод</span>
            <p>{{ driveUnit }}</p>
          </div>
        </div>
        <div class="car_card__price">
          <span :class="discNumber ? 'car_card__price--crossed' : ''"
            >день от <b> {{ spaceInNumbers(price) }} ₽</b></span
          >
          <span class="car_card__special_price" v-if="discNumber"
            ><b> {{ spaceInNumbers(finalPrice) }} ₽</b></span
          >
        </div>
        <div class="car_card__buttons">
          <button class="btn_with_corner" @click.prevent="openRent">
            Арендовать
          </button>
          <button class="btn_with_corner" @click.prevent="openBuy">
            Выкупить
          </button>
        </div>
      </div>
    </div>
  </a>
</template>

<script setup>
import spaceInNumbers from "@/utils/spaceInNumbers";
const emits = defineEmits(["openBuy", "openRent"]);

const props = defineProps({
  link: {
    type: String,
  },
  title: {
    type: String,
  },
  year: {
    type: String,
  },
  mark: {
    type: String,
  },
  equipment: {
    type: String,
  },
  kpp: {
    type: String,
  },
  volume: {
    type: String,
  },
  power: {
    type: String,
  },
  special: {
    type: String,
  },
  fuel: {
    type: String,
  },
  driveUnit: {
    type: String,
  },
  price: {
    type: Number,
  },
  img: {
    type: String,
  },
  discProcent: {
    type: String,
  },
});

const priceNumber = Number(props.price);
const discNumber = Number(props.discProcent);

const finalPrice =
  discNumber && priceNumber - (priceNumber / 100) * props.discProcent;

const openRent = () => {
  emits("openRent", {
    title: `${props.mark} ${props.title}`,
    price: spaceInNumbers(props.price),
  });
};
const openBuy = () => {
  emits("openBuy", {
    title: `${props.mark} ${props.title}`,
    price: spaceInNumbers(props.price),
  });
};
</script>

<style lang="scss">
.car_card {
  clip-path: polygon(100% 0, 100% 96.7%, 94.7% 100%, 0 100%, 0 0);
  background: linear-gradient(
    160.89deg,
    rgba(255, 255, 255, 0) 25.58%,
    #b1b6c2 100%
  );
  background-size: 900%;
  transition: 0.5s ease;
  position: relative;
  overflow: hidden;

  padding: 25px;
  width: 370px;
  height: 570px;
  box-sizing: border-box;
  color: #2b0600;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border: 2px solid #d8d8d8;

  border-width: 2px 0 0 2px;
  &::before,
  &::after {
    transition: 0.5s ease;
    border: 2px solid #d8d8d8;
    content: "";
    position: absolute;
  }

  &::before {
    width: 100%;
    height: 20px;
    border-width: 0 2px 2px 0;
    bottom: 0;
    left: -10px;
    transform: skew(-45deg);
  }

  &::after {
    height: calc(100% - 10px);
    border-width: 0 2px 0 0;
    top: -10px;
    right: 0;
  }

  &:hover {
    background-size: 100%;

    &,
    &::before,
    &::after {
      border-color: #1f0052;
    }

    .car_card__head {
      color: #1f0052;
    }
  }

  &__head {
    transition: 0.5s ease;
    position: absolute;
    top: 0;
    left: 50%;
    color: #d8d8d8;
    transform: translate(-50%);
  }

  &__wrapper {
    position: relative;

    &:hover {
      .bamboo {
        transform: none;
      }
    }
  }

  &__special {
    z-index: 1;
    padding: 0 20px;
    position: absolute;
    top: 10px;
    right: -10px;
    height: 35px;
    min-width: 80px;
    display: flex;
    background: #ff2100;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #f9fdff;

    .red_arrow {
      width: 0;
      height: 0;
      position: absolute;
      left: -10px;
      border-top: 17.5px solid transparent;
      border-bottom: 17.5px solid transparent;

      border-right: 10px solid #ff2100;
    }
  }

  .bamboo {
    top: 8%;
    transition: 0.5s ease;
    position: absolute;
    z-index: -1;
    left: 4px;
    transform: translate(-100%);
  }

  &__img {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    position: relative;

    img {
      width: 290px;
      height: 156px;
    }
  }

  &__shadow {
    z-index: -1;
    position: absolute;
    top: 63%;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    height: 32%;

    > svg {
      width: 100%;
      height: 100%;
      filter: blur(15px);
    }
  }

  &__special_price {
    color: #ff2100;
  }

  &__price {
    display: flex;
    justify-content: end;
    align-items: end;
    gap: 6px;

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    margin-bottom: 20px;

    b {
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
    }

    &--crossed {
      b {
        position: relative;
        color: #9384ab;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;

        &::before {
          display: block;
          position: absolute;
          content: "";
          width: 100%;
          height: 2px;
          background-color: currentColor;
          left: 0;
          bottom: 40%;
          transform: rotate(12deg);
        }
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;

    > button {
      font-size: 16px;
      width: 150px;
      height: 35px;
      background-position-y: 50%;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 10px;

    > div {
      display: flex;
      justify-content: space-between;

      > span {
        font-weight: 600;
      }
    }
  }

  &__title {
    font-family: Furore;
    font-size: 20px;
    line-height: 30px;
    text-align: center;

    margin-bottom: 10px;
  }
}
</style>
